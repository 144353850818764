.login-wrapper{
    background-image: url('../../../Assets/Login/loginbg.svg');
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.login-banner-bg-wrapper{
    background: rgba(0,0,0,0.75);
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-banner{
   width: 65vw;
   height: 80vh;
   background: #FFFFFF;
   border-radius: 30px;
    padding: 10px auto;
   display: flex;
   align-items: center;
   justify-content: space-evenly;

}
.login-icon-container{
    width: 42%;
    /* height: 70vh; */
    /* padding: 10px; */
    /* position: relative; */
    border-right: 1px solid #BCBCBC ;
}
.logo-div{
    height:8.3vh;
}
.login-form-div{
    /* border-left: 1px solid rgba(188, 188, 188, 1); */
    width: 45%;
    
}
.divider-wrapper{
    background: #BCBCBC ;
    width: 1px;
    height: 90%;
    display: none;
    }
.login-img{
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.login-text-div{
    /* border: 1px solid red; */
}
.login-text{
    font-family: 'semibold';
    color:#9D6AFC;
    font-size: 20px;
}
.welcome-text{
    font-family: 'regular';
    color: rgba(77, 77, 77, 1);
    margin-top: 5px;
}
.welcome-text-span{
    font-family: 'semibold';
    color:rgba(17,17,17,1);
    text-decoration: none;
}
.privacy-policy-div{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:rgba(77, 77, 77, 1);
    margin-top: 40px;
}
.privacy-policy-div a{
    text-decoration: none;
    color: #4D4D4D;
    font-family:"regular"
}
.privacy-policy-div p{
    text-decoration: none;
    color: #4D4D4D;
    font-family:"regular"
}
.privacy-policy-div a:active{
    color:#4D4D4D;
}
.form-welcome-text{
    font-family: 'bold';
    color:#9D6AFC;
    font-size: 25px;
    /* padding-top: 20px; */
    width: 100%;
}
.from-sign-in-text{
    color: rgba(77, 77, 77, 1);
    margin-bottom: 10px;
    font-size: 15px;
}

.remember-prefrence{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: rgba(77, 77, 77, 1);
    width: 80%;
    font-family: 'regular';
}

.ant-form-item-label{
 color:rgba(77, 77, 77, 1);
 font-family: 'semibold';
}.ant-input ,.ant-input-password{
    border-radius: 20px;
    padding: 6px 18px;
    /* font-family: 'semibold'; */
    width: 100% !important;
}
#form-input{
    width: 100% !important;
}
.remember-pref-div{
    text-align: center;
    color: rgba(77, 77, 77, 1);
    font-family: 'regular';
    font-size: 16px;
}
.ant-form-item {
    margin-bottom: 5px;
}
.email-label{
    margin-top: 20px;
}
.ant-btn{
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(108.83deg, #895EE9 8.99%, #37316E 84.84%);
    height: 6vh;
    border: none;
    font-family: 'semibold';
}
.mobile-logo-div,.mobile-login-footer{
    display: none;

}
.ant-input-password :focus{
    border: none !important;
    border-radius: 20px;
    padding: 6px 18px;
    width: 100%;
    outline: none !important;
}

.warning-text{
    color: #d95637;
    font-size: 14px;
    font-family: "regular";
    text-align: center;
} .warning-border{
    background: #fefaf9;
    border: 2px solid #fcf0ed;
    padding: 15px;
    font-size: 12px;
    margin-bottom: 20px;
}
.success-text{
    color: green;
    font-size: 14px;
    font-family: "regular";
    text-align: center;
}
.success-border{
    /* background:; */
    border: 1px solid green;
    padding: 15px;
    font-size: 12px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-container{
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    z-index: 9;
    background: rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
}
.loader-container svg{
    width: 500px !important;
}

@media (min-width:320px) and (max-width:768px){
    .login-banner{
        width: 96vw;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .login-icon-container{
        display: none;
    }
    .login-form-div{
        border: none;
        width: 100%;
        padding: 0;
        height: fit-content;
    }
    .form-welcome-text{
        font-size: 24px;
        text-align: center;
    }
    .from-sign-in-text{
        font-size: 20px;
        text-align: center;

    }
    .mobile-logo-div,.mobile-login-footer{
        display: block;
    }
    .mobile-logo-div img{
        width: 60vw;
        padding-top: 20px;
    }
    
    .login-text{
        text-align: center;
    }
    .footer-mobile-links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4D4D4D;
        margin-top: 15px;
    }
    
    
}
@media (min-width:769px) and (max-width:992px){
    .login-banner{
        width: 95vw;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        padding: 20px;
    }
    .login-icon-container{
        display: none;
    }
    .login-form-div{
        border: none;
        width: 100%;
        padding: 0;
        height: fit-content;
    }
    .form-welcome-text{
        font-size: 32px;
        text-align: center;
    }
    .from-sign-in-text{
        font-size: 20px;
        text-align: center;

    }
    .mobile-logo-div,.mobile-login-footer{
        display: block;
    }
    .mobile-logo-div img{
        width: 60vw;
        padding-top: 20px;
    }
    
    .login-text{
        text-align: center;
    }
    .footer-mobile-links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4D4D4D;
        margin-top: 15px;
    }
    .ant-form-item-control-input-content{
        text-align: center;
    }
    .ant-btn{
        width: 60%;
        border-radius: 50px;
        font-size: 20px;
        height: 5vh;
    }
}