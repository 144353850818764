#input{
    font-family: 'regular' !important;
}
#input1{
    width: 100% !important;
}
.btn-div-one button{
    color: #FFFFFF !important;
    margin-top: 10px ;
}
.close-divii{
    position: absolute;
    right: 20px;
    font-family: 'bold';
    top:10px;
    cursor: pointer;
    border: 2px solid;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#set_price {
    width: 100% !important
}