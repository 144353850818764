.admin-balance-card-wrapper{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 10px;
}
.balnce-divi{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 5px auto;
}
.balnce-divi p {
    font-size: 22px;
    font-family: "regular";
}
.admin-card-holder{
    width: 95%;
    margin: auto;
}
.add-balance{
    cursor: pointer;
}
.divider-wrapp{
    height: 1px;
    background: #DADADA;
    width: 90%;
    margin:  10px auto;
}