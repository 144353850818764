.admin-header{
    padding:0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_btn_div{
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile_div{
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile_div img{
    width: 40px;
    cursor: pointer;
}
.dashboard-title{
    display: flex;
    flex-direction: column;
}
.dashboard_button  {
    width: 27%;
}
.dashboard_button button{
    width: 100%;
    font-family: 'semibold';
    height: 6vh;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logout_link{
    font-size: 13px;
    color: #0096FF;
    font-family: 'bold';
    text-decoration: none;
}
.profile-name{
    text-align: right;
}
#dashboard-title{
    font-family: bold;
    font-size: 25px;
}
#welcome-div{
    font-family: bold;
    font-size: 15px;
}
#welcome-span{
    color: #4d4d4d;
    font-family: 'regular';
}
#title-text{
    font-family: bold;
    font-size: 15px;
}
#email-text{
    font-size: 13px;
    color: #4D4D4D;
    font-family: 'regular';
}
.wallet-div-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}.wallet-div-wrapper img{
    width: 15px;
    margin-right: 5px;
}