.admin-main-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    background: #EFF3FD;
    justify-content: space-evenly;
    overflow: hidden;
    position: relative;
}
.loader-contain{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.75);
    z-index: 999;
    text-align: center;
}
.loader-contain svg{
    width: 500px !important;
}
.admin-content-wrapper{
    width: 77%;
    height: 90vh;
    margin-top: 1%;
}
.ant-modal-footer{
    display: none !important;
}
.ant-modal-close{
    display: none !important;
}
.ant-modal-content{
    border-radius: 20px !important;
}
.connect-wallet-div{
    position: relative;
}
.wallet-header{
    font-family: 'bold';
    text-align: center;
    color: #1B1773;
    font-size: 35px;
}
.wallet-paragraph{
    text-align: center;
    color: #000000;
    font-family: 'regular';
    margin-top: 20px;
    margin-bottom: 40px;
}
.wallet-div{
    background: #FFFFFF;
box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12);
border-radius: 20px;
width: 110px;
height: 110px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
position: relative;
}
.wallet-div img{
    width: 45px;
}
.wallet-div p{
    font-family: 'regular';
}
.modal-div-layout{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.25);
    width: 110px;
    height: 110px;
border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-div-layout p{
    color: #FFFFFF;
    font-family: 'semibold';
}
.wallet-handler{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.close-icon{
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    cursor: pointer;
}
