.ant-table-wrapper .ant-table-thead > tr > th{
    background: #04E2AF!important;
    color: #FFFFFF !important;
    /* line-height: 0 !important; */
    padding: 10px 10px !important;
    font-family: 'regular';
} 
.ant-table-cell::before{
    display: none !important;
}
.ant-table-wrapper{
    border-radius: 0px !important;
}
.ant-table-row{
    padding: 0px !important;
}

.admin-antd-table{
    height: 40vh !important;
    overflow: scroll;
}
.payment-antd-table{
    height: auto;
}
.admin-antd-table::-webkit-scrollbar{
    display: none;
}
.view-all-wrapper{
    /* border: 1px solid green ; */
}

