.selection-input{
    border: transparent !important;
    border-bottom: 1px solid gray !important;
    border-radius: 0px !important;
    font-family: 'semibold';
    outline: none !important;
    width: 30% !important;
}::placeholder{
    font-family: 'regular'!important;
}
.selection-input:focus{
}
.selected_divi_comp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    width: 100%;
    margin-bottom: 8px;
}
.ant-tooltip-content{
    top:20px !important;
}
 
 .ant-tooltip .ant-tooltip-inner {
    color: #000000 !important;
    background: transparent !important;
    border-radius: none !important;
    box-shadow: none !important;
    padding: 2px 8px !important;
}
.ant-tooltip .ant-tooltip-arrow{
    display: none !important;
}
.ant-tooltip{
    --antd-arrow-background-color:none !important;
    z-index: 0 !important;


}
.range-picker-comp{
    width: 30%;
}
.slider-comp-antd{
    width: 30%;
    
    margin-right: 20px;
}
.ant-slider .ant-slider-track{
    background-color: #9C6AFD!important;
} 
.ant-slider .ant-slider-handle::after{
    background-color: #9C6AFD!important;
    box-shadow: none !important;
}
  .ant-input
 {
    /* border-bottom:  1px solid gray !important; */
    outline: none !important;
}