.dashboard_wrapper {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  height: 100%;
}
.dashboard_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}
.dashboard_content img {
  width: 40px;
  height: 40px;
}
.icon-value-div {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  width: 50%;
}
.icon-value-div img{
  width: 70px;
  margin-top: 10px;
}
#total-tokens {
  font-family: "Bold";
  padding-left: 10px;
}
#token-span {
  color: #9c6afd;
}
#locked-balance-header {
  font-size: 12px;
  text-align: right;
  color: #626262;
}
#locked-balance{
    font-family: 'bold';
}
#locked-img{
    width: 18px;
    height: 18px;
}
#usd-value{
    font-size: 11px;
    color: #1B1773;
    font-family: 'semibold';
    /* margin-right: 5%; */
    width: 50%;
    display: flex;
    align-items: center;
}
.ant-skeleton{
height: 20px !important;
width: 50px !important;
margin-left: 10px;
}
 .ant-skeleton-content{
margin: 0 !important;
 }
 .ant-skeleton-title{
  width: 100% !important;
  height: 100% !important;
 }
 .ant-skeleton-paragraph{
  display: none !important;
 }