.content_wrapper{
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.admin_card_div{
    width: 68%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.admin-card-balances{
    /* border: 1px solid green; */
    width: 30%;
}
.balances_div{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 5px;
}
.graph-div-overview{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 15px;
    margin-top: 30px;
}
.dashboard_div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 17%;
}
.admin-table-wrapper{
background: #FFFFFF;
border-radius: 20px;
padding: 10px;
margin-top: 10px;
height: 60vh;
}
.admin-table-button{
}
.admin-table-button button{
    font-family: 'semibold';
    width: 20%;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
}
.antd-btn-wrapper{
    border: 1px solid #9C6AFD!important;
    color: #1B1773!important;
    font-family: 'semibold';
    background: #FFFFFF !important;

}
.admin-filter-div{
    background: #FFFFFF;
    padding: 10px;
    position: relative !important;
}
.admin-filter-div .ant-table-wrapper .ant-table-thead > tr > th{
    background: #04E2AF!important;
    color: #FFFFFF !important;
    padding: 10px 10px !important;
    font-family: 'regular';
} 
/* .admin-filter-div .ant-table-wrapper .ant-table-thead > td > th{
    color: green !important;
} */
/* .ant-table-row{
    /* font-family: 'semibold' !important;
    font-size: 13px !important; 
    /* font-family: 'regular'; 
} */
.nodata-liquidity{
    position: absolute;
    top: 60%;
    left: 25%;
    color: #E80B0B;
    transform: rotate(20deg)
}
.admin-antd-table{
    /* margin-top: 5px; */
    /* border: 1px solid green; */
    /* position: relative !important; */
}
.liquidity-header{
    font-family: 'semibold';
    margin-left: 10px;
    margin-top: 10px;
}
.owner-error-div{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E80B0B;
    font-family: 'regular';
    padding-top: 10px;
}
.liquidity-button-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    margin-left: 2%;
    position: absolute;
    bottom:2%;
 
}
.liquidity-button-div button{
    width: 40%;
    font-size: 13.5px;
    height: 6vh;
    border: none;
}
.liquidity-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.liquidity-wrapper img{
    width: 20px;
    margin-right: 5px;
}

.ant-tabs-tab{
    padding: 5px 30px !important;
    border-radius: 20px;
color: #1B1773 !important;

background: #FFFFFF !important;
font-family: 'semibold';
border: 1px solid #9C6AFD !important; 
}
.ant-tabs-tab-active {
    padding: 5px 30px !important;
    border-radius: 20px !important;
    color: #FFFFFF !important;
background: linear-gradient(103.45deg, #865CE6 17.59%, #2E2387 91.71%) !important;
font-family: 'semibold';
border: none !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: inherit !important;
}
.ant-tabs .ant-tabs-ink-bar{
    display: none !important;
}
.ant-tabs-top .ant-tabs-nav::before{
border: none;
}
.ant-tabs .ant-tabs-tab {
    font-size: 12px;
}
.ant-tabs-nav{
    width: 90%;
    /* margin-top: 5px !important; */
    margin-bottom: 8px !important;
}
.ant-tabs .ant-tabs-tab{
    margin: 0px !important;
    margin-right: 2px !important;
}
.add-liquidity-modal{
    position: relative;
}
.add-liquidity-modal .rejected{
    font-family: "Semibold";
    font-size: 30px;
    text-align: center;
}
.add-liquidity-modal .close-icon{
    position: absolute;
    top: 10px;
    right: 10px;
}
.close-div{
    position: absolute;
    right: 3%;
    top: 7%;
}
.close-div img{
    width: 20px;
    cursor: pointer;
}
.lct-main-wrapper-div{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.modal-content-wrapper{
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.balance-header{
    text-align: center;
    font-family: 'semibold';
    font-size: 20px;
}
.lct-main-wrapper-div{
    width: 55%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center
}
.logo-div-wrapper-icon img{
    width: 40px;
    margin-right:10px ;
}
.lct-val-text{
    font-size: 20px;
    font-family: 'semibold';
}
.usd-lct-text{
color: #1B1773;
transform: translateY(-5px);
font-family: 'semibold';
}
.divider-modal{
    height: 1px;
    background: rgba(0, 0, 0, 0.2);

    width: 100%;
    margin: auto;
    margin-top: 10px;
}
#liquidity-title{
    text-align: center;
    font-size: 30px;
    font-family: 'bold';
    color: #1B1773;
    margin-top: 20px;
    

}
#liquidity-amount-title{
    text-align: center;
    font-family: 'semibold';
    font-size: 15px;

}
.liquidity-button-div-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-top: 10px;
}
.liquidity-button-div-wrapper-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: auto;
    margin-top: 10px;
}
.liquidity-button-div-wrapper-container .network-data-currency {
    color: #F3BA2F;
    font-family: regular;
    position: absolute;
    right: 180px;
    font-size: 12px;
}
.max-input-div{
    border: 1px solid #B1B1B1;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
    width: 70%;
}
.max-input-div input{
    width: 70%;
    outline: none;
    border: none;
    text-align: center;
    font-family: 'semibold';
}
.max-input-container {
    height: 40px;
    width: 58%;
    text-align: center;
    border: 1px solid #B1B1B1;
    border-radius: 25px;
}
.max-input-div input::placeholder{
    text-align: center;
    color: #000000;
    font-family: 'semibold'!important;
}
/* .ant-table-body{
    max-height: 90px !important;
    border: 1px solid red !;
} */
.liquidity-btn-div{
    background: #04E2AF;
    color: #000000;
    font-family: 'semibold';
    width: 45px;
    height: 45px;
    border-radius: 50px;
 font-size: 30px;
 cursor: pointer;
text-align: center;
border: none;
}
.disabled{
    background: #04E2AF;
    color: #000000;
    font-family: 'semibold';
    width: 45px;
    height: 45px;
    border-radius: 50px;
 font-size: 30px;
    cursor: not-allowed;
    text-align: center;
border: none;
}
.btn-main-wrappr{
    text-align: center;
    width: 100%;
    margin-top: 15px;
}
.btn-main-wrappr button{
    border: none;
    width:50%;
}
.container{
    height: 35vh;
}
.transferownership-wrapper{
}
#transfer-title{
    color: #1B1773;
    text-align: center;
    font-family: 'semibold';
    font-size: 30px;
    margin-top: 20px;
}
.transfer-owner-title{
    text-align: center;
    font-family: 'semibold';
}
.wallet-addr-div{
    color: #828282;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ownership-input input{
    width: 80%;
    height: 5vh;
    text-align: center;
    border-radius: 20px;
    outline: none;
    border: 1px solid #B1B1B1;
    font-family: 'semibold';
}
.ownership-input-div input{
    width: 80%;
    height: 5vh;
    text-align: center;
    border-radius: 20px;
    outline: none;
    border: 1px solid #FF1B1B;
    font-family: 'semibold';
}
.ownership-input input::placeholder{
    font-family: 'semibold';
    color: #000000;
}
.ownership-input,.ownership-input-div{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.ownership-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.ownership-button button{
    width: 35%;
    height: 6vh;
    border: none;
    font-size: 10px;
    border-radius: 15px;
}
.owner-addr{
    background: #FEDBDB;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    color: #E80B0B;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'regular';
}

.alert-error{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}
.alert-error img{
    width: 25px;
}
.alert-error p{
    color: #FF1B1B;
    font-family: 'semibold';
}
#graph-img{
width: 100%;
}
.admin_setting_div{
    background-color: #FFFFFF;
    width: 67%;
    height: 85vh;
    border-radius: 20px;
    padding: 20px;
}
.settings-header{
    font-family: 'bold';
}
.username-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between ;
    width: 100%;
}
.lname-wrapper{
    width: 30%;
}
.ant-form-item .ant-form-item-label >label {
    color: black !important;
}
.ant-form-item .ant-form-item-label >label::after {
    display: none;
}
.form-div-antd{
    max-width: 100%  !important;

}
.fname-wrapper{
    width: 30%;

}
.ant-input{
    width: 150% !important;
}
.lname-wrapper{
    width:30% ;
}
.btn-div{
    width: 150% !important;
}
.btn-div button{
    width: 100%;
    background: #E1E1E1 !important;
    color: #10495F;
    font-size: 10px;

}
.text-area-ant{
    border-radius: 10px !important;
}
.update-btn-div{
    width: 25%;
}
.update-btn-div button{
    width: 150%;
}