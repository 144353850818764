.adminsidebar-wrapper{
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    width: 18%;
    height: 96%;
    margin-top: 1%;
    padding: 1.5%;
    position: relative;
}
.sidebar-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.sidebar-logo img{
    cursor: pointer;
}

.divider_div{
    height: 1px;
    width: 80%;
    margin: auto;
    background: #E4E7ED;
    margin-top: 15px;
    margin-bottom: 5px;
}
.link-div{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.link-wrapper{
    display: flex;
    align-items: center;
    margin-top: 25px;
    text-decoration: none;
    color: black;
    font-family: 'regular';
    font-size: 15px;
}
.link-wrapper img,.isactive img{
    margin-right: 10px;
}
.logout-div{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-family: 'semibold';
    width: 80%;
    margin: auto;
    background: rgba(255, 0, 0, 0.03);
    border-radius: 10px;
    /* z-index: 99999; */
}
.logout-div a{
    color: #E45A5A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "semibold";
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

}
.isactive{
    display: flex;
    align-items: center;
    margin-top: 25px;
    text-decoration: none;
    color:#00DEA3;
    font-family: 'regular';
  font-family: 'semibold';
  font-size: 15px;

}
.isactive::after{
    content: "";
  background-color: #00DEA3;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.react-icons{
    margin-right: 10px;
}
.sidebar-logo img{
    width: 90%;
}
