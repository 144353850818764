.admin-graph-wrapper {
  padding: 5px;
}
.admin-graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-graph-header .ant-select-arrow {
  display: block !important;
  color: #ffffff !important;
}
.admin-graph-header .ant-select-selector {
  background: #00dea3 !important;
  color: #ffffff !important;
  font-family: "semibold";
  border: none !important;
}
.market-overview {
  font-size: 18px;
  font-family: "semibold";
}
.market-value {
  color: "#00DEA3";
  font-size: 13px;
}
.select-all-div{
  width: 75;
}