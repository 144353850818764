.bal-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo-and-baldiv{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo-and-baldiv img{
    width: 45px;
    height: 45px;
}
.percentage-div{
    width: 15%;
    color: red;
}